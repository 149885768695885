import { type ElementType } from 'react';
import { FontAwesomeIcon, type FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import { type IconProp } from '@fortawesome/fontawesome-svg-core';

import cx from '@/lib/cx';

import Link from './Link';

type NavbarIconButtonProps<C extends ElementType> = PolymorphicComponentProp<
  C,
  {
    icon: IconProp;
    as?: C;
    className?: string;
    iconProps?: Omit<FontAwesomeIconProps, 'icon'>;
    theme?: 'light' | 'dark' | 'primary';
    border?: boolean;
    disabled?: boolean;
  }
>;

const NavbarIconButton = <C extends ElementType = typeof Link>({
  as,
  icon,
  className = '',
  iconProps = null,
  theme = 'light',
  border = false,
  disabled = false,
  ...props
}: NavbarIconButtonProps<C>) => {
  const Component = as || Link;
  return (
    <Component
      className={cx(
        'flex items-center justify-center transition-colors duration-200 h-9 w-9 rounded-full cursor-pointer',
        {
          border,
          'pointer-events-none': disabled,

          'text-gray-800 bg-white border-gray-400 hover:bg-gray-300 hover:border-gray-800':
            theme === 'light' && !disabled,
          'bg-gray-100 text-gray-500': theme === 'light' && disabled,

          'text-white bg-black/50 border-gray-800 hover:bg-black/40 hover:border-gray-700':
            theme === 'dark' && !disabled,
          'bg-black/30 text-gray-500': theme === 'dark' && disabled,

          'text-white bg-theme-primary border-theme-primary hover:bg-theme-primary/80 hover:border-theme-primary/80':
            theme === 'primary' && !disabled,
          'bg-theme-primary/50 text-white/80': theme === 'primary' && disabled,
        },
        className
      )}
      {...props}
    >
      {icon && <FontAwesomeIcon icon={icon} size="1x" {...iconProps} />}
    </Component>
  );
};

export default NavbarIconButton;
